import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const CampaignsContext = createContext({} as any);

export const useCampaignsContext = () => {
  const context = useContext(CampaignsContext);

  if (!context)
    throw new Error('useSettingsContext must be use inside SettingsProvider');

  return context;
};
