import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const FormsContext = createContext({} as any);

export const useFormsContext = () => {
  const context = useContext(FormsContext);

  if (!context)
    throw new Error('useSettingsContext must be use inside SettingsProvider');

  return context;
};
