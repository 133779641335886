// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 40,
  H_DESKTOP: 70,
  H_DESKTOP_OFFSET: 70 - 16,
};

export const NAV = {
  W_VERTICAL: 220,
  W_MINI: 88,
};
