import { createContext, useContext } from 'react';
//
// import { DashboardContextProps } from '../types';

// ----------------------------------------------------------------------

export const DashboardContext = createContext({} as any);

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (!context)
    throw new Error('useSettingsContext must be use inside SettingsProvider');

  return context;
};
