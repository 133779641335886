import { forwardRef } from 'react';
// @mui
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
// hook
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>((props, ref) => {
  const { disabledLink, sx } = props;
  const theme = useTheme();
  const logoSrc =
    theme.palette.mode === 'light'
      ? '/logo/logo_single.svg'
      : '/logo/logo_single-white.svg';

  const logo = (
    <Box
      component="img"
      src={logoSrc}
      ref={ref}
      sx={{ width: 120, height: 'auto', cursor: 'pointer', ...sx }}
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

export default Logo;
