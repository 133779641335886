// import { useCallback, useEffect } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useSearchParams } from 'src/routes/hooks';
//
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: Props) {
  // const router = useRouter();

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo') || paths.dashboard.root;

  const { authenticated } = useAuthContext();

  if (authenticated) {
    return <Navigate to={returnTo} replace />;
  }

  // const check = useCallback(() => {
  //   if (authenticated) {
  //     router.replace(returnTo);
  //   }
  // }, [authenticated, returnTo, router]);

  // useEffect(() => {
  //   check();
  // }, [check]);

  return <>{children}</>;
}
